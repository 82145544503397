<template>
  <div class="site-container">
    <Container color="var(--primary-light)" msg="Hey girl" >
      <Wedding />
    </Container>
    <!-- <Container color="var(--primary-light)" msg="Hey boy" ></Container>
    <Container color="var(--primary-light)" msg="Superstar DJ" ></Container>
    <Container color="var(--primary-light)" msg="Here we go!" ></Container> -->
  </div>
</template>

<script>
import Container from './components/Container.vue'
// import Hero from './components/Hero.vue'
import Wedding from './components/Wedding.vue'
// import SubContainer from './components/SubContainer.vue'

export default {
  name: 'App',
  components: {
    Container,
    // Hero,
    Wedding
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,100..900;1,100..900&family=Barriecito&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Asap", sans-serif;
  font-size: 20px;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

.site-container {
  display: flex;
  overflow-x: auto;
  height: 100vh;
  scroll-snap-type: x proximity;
}
</style>
