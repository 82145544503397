<template>
    <div class="container" >
        <div class="inner" :style="{ backgroundColor: color }">
            <div class="scroll">
                <slot></slot>
            </div>

        </div>
    </div>
</template>

<script>
export default {
  name: 'Container',
  props: {
    color: {
      type: String,
      default: 'lightcoral',
    },
    msg: String
  }
}
</script>

<style scoped lang="scss">
@import './src/styles/global.scss';

.container {
    --border-thickness: 80px;

    height: 100%;
    width: 100%;
    flex-shrink: 0;
    scroll-snap-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--border-thickness);

    @media screen and (max-width: $breakpoint-medium) { 
        --border-thickness: 20px;
    }
}

.inner {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        transform: matrix(1, 1, 0, 1, 0, 0);
        width: var(--initial-depth-width);
        height: 100%;
        left: 0;
        top: calc(var(--initial-depth-width) / 2);
        background-color: var(--primary-darker);
        position: absolute;
        @include breathe-horizontal();
    }

    &::after {
        content: "";
        transform: matrix(1, 0, 1, 1, 0, 0);
        width: 100%;
        height: var(--initial-depth-width);
        top: 0;
        left: calc(var(--initial-depth-width) / 2);
        background-color: var(--primary-darkest);
        position: absolute;
        @include breathe-vertical();
    }
}

.scroll {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;

    &::-webkit-scrollbar { 
        display: none;
    }
}
</style>