<template>
        <div class="subcontainer">
            <slot></slot>
        </div>
</template>

<script>
export default {
  name: 'SubContainer',
  props: {
    msg: String
  }
}
</script>

<style scoped lang="scss">
@import './src/styles/global.scss';

.subcontainer {
    display: flex;
    // justify-content: center;
    // align-items: center;
    background-color: var(--primary-dark);
    min-height: 100px;
    position: relative;
    z-index: 1;
    // padding: 20px;

    // &:hover {
    //     background-color: var(--secondary-lighter);

    //     &::before {
    //         background-color: var(--secondary-light);
    //     }

    //     &::after {
    //         background-color: var(--secondary-dark);
    //     }
    // }
}

.subcontainer::before {
    content: "";
    transform: matrix(1, 1, 0, 1, 0, 0);
    width: var(--initial-depth-width);
    height: 100%;
    left: 100%;
    top: calc(var(--initial-depth-width) / 2);
    background-color: var(--primary-darker);
    position: absolute;
    @include breathe-horizontal;
}

.subcontainer::after {
    content: "";
    transform: matrix(1, 0, 1, 1, 0, 0);
    width: 100%;
    height: var(--initial-depth-width);
    top: 100%;
    left: calc(var(--initial-depth-width) / 2);
    background-color: var(--primary-darkest);
    position: absolute;
    @include breathe-vertical;
}
</style>