<template>
 <a class="link" :href="url"><span :data-title="text">{{ text }}</span></a>
</template>

<script>
export default {
    name: 'TextLink',
    props: {
        text: String,
        url: String
    }
}
</script>

<style scoped lang="scss">
@import './src/styles/global.scss';

a, a > span {
  position: relative;
  color: inherit;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  width: fit-content;
}
a:before, a:after, a > span:before, a > span:after {
  content: '';
  position: absolute;
  transition: transform .5s ease;
}

.link {
  font-weight: bold;
  display: inline-flex;
  overflow: hidden;
}
.link:before, .link:after {
  right: 0;
  bottom: 0;
  background: var(--secondary-lighter);
  transition: transform .3s ease;
}
.link:before {
  width: 100%;
  height: 2px;
  transform: translateX(-100%);
  transition-delay: .9s;
}
.link:after {
  width: 2px;
  height: 100%;
  transform: translateY(100%);
  transition-delay: .6s;
}
.link > span {
  display: flex;
  padding: 3px;
}
.link > span:before, .link > span:after {
  left: 0;
  top: 0;
  background: var(--secondary-lighter);
  transition: transform .3s ease;
}
.link > span:before {
  width: 100%;
  height: 2px;
  transform: translateX(100%);
  transition-delay: .3s;
}
.link > span:after {
  width: 2px;
  height: 100%;
  transform: translateY(-100%);
  transition-delay: 0s;
}
.link:hover:before, .link:hover:after, .link:hover > span:before, .link:hover > span:after {
  transform: translate(0, 0);
}
.link:hover:before {
  transition-delay: 0s;
}
.link:hover:after {
  transition-delay: .3s;
}
.link:hover > span:before {
  transition-delay: .6s;
}
.link:hover > span:after {
  transition-delay: .9s;
}

.link:focus-visible {
    outline: 2px solid var(--secondary-lighter);
    outline-offset: -2px;
}

</style>