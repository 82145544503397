<template>
    <div class="hero">
        <SubContainer>
            <div class="heading">
                <h1>Isa & Jon</h1>
            </div>
        </SubContainer>
        <SubContainer>
            <div class="info">
                <h2>We're getting married! </h2>
<p>👰🏼‍♀️💒🤵🏻‍♂️</p>
                <p>we are having a little backyard wedding at home with a small group of friends 💕</p>
                <h3>When?</h3>
                <p>Sunday May 26th @ 4pm</p>
                <h3>Wear?</h3>
                <p>Cocktail attire</p>
                <h3>Where?</h3>
                <p>724 SE 27th Ave</p>
                <p>Portland, OR 97214</p>
                <TextLink url="https://www.google.com/maps/dir/?api=1&destination=724+SE+27th+Ave,+Portland,+OR+97214" text="Get Directions"/>
            </div>
        </SubContainer>
        <SubContainer>
            <div class="info info-2">
            </div>
        </SubContainer>
        <SubContainer>
            <div class="info info-3">
                <h2>Registry</h2>
                <div>
                    <TextLink url="https://venmo.com/u/Isabella-Abatgis" text="Venmo"/>
                </div>
                <div>
                    <TextLink url="https://www.amazon.com/wedding/registry/E0UAJ3ZMIFXW" text="Amazon Registry"/>
                </div>
            </div>
        </SubContainer>

        <div class="spacer"></div>

        <SubContainer class="jerry-container-container">
            <div class="jerry-container">
                <img class="jerry" src="../assets/jerry-cutout.png" alt="Jerry the black cat">
                <img class="jerry" src="../assets/jerry-cutout-2.png" alt="Jerry the black cat">
                <img class="jerry" src="../assets/jerry-cutout-3.png" alt="Jerry the black cat">
            </div>
        </SubContainer>
    </div>
</template>

<script>
import SubContainer from './SubContainer.vue'
import TextLink from './TextLink.vue'

export default {
    name: 'Hero',
    props: {
        msg: String
    },
    components: {
        SubContainer,
        TextLink
  },
}
</script>

<style scoped lang="scss">
@import './src/styles/global.scss';

.hero {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 40px;
    // margin: 40px;
    padding: 40px;
    height: inherit;
    height: -webkit-fill-available;

    > :first-child {
        grid-row: span 3;
    }

    > :nth-child(2) {
        grid-row: span 2;
    }

    > :last-child {
        grid-column: span 2;
    }

    h2 {
        @include h3;
    }

    @media screen and (max-width: $breakpoint-medium) {
        display: block;

        > * {
            margin-bottom: 30px;
        }
    }
}

.heading {
    background-image: url('../assets/proposal-1.jpg');
    background-size: cover;
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    grid-template-rows: auto auto 1fr;
    overflow: hidden;
    width: 100%;
    padding: 20px;

    h1 {
        @include h1;
    }

    p {
        @include h3;
    }

    .headshot-smiling {
        display: none;
    }

    .headshot-smiling {
        display: none;
    }

    @media screen and (max-width: $breakpoint-medium) {
        aspect-ratio: 9 / 16;
    }
}

.info {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);
    overflow-y: auto;
    height: 100%;
    padding: 20px;
    h2 {
        @include h3;
    }

    h3 {
        @include h4;
    }
}

.info-2 {
    background-image: url('../assets/proposal-horiz.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
    background-position: bottom;

    @media screen and (max-width: $breakpoint-medium) {
        aspect-ratio: 9 / 16;
    }
}

.jerry-container {
    height: 100%;
    grid-column: span 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;

    .jerry {
        height: 100%;
        transform: rotate(0deg);
        object-fit: contain;
        max-width: 25%;
        aspect-ratio: 1;

        &:hover {
            transition: transform 250ms;
            transform: rotate(360deg);
        }
    }
}

.jerry-container-container {
    @media screen and (max-width: $breakpoint-medium) {
        display: none;
    }
}

.spacer {
    display: none;

        @media screen and (max-width: $breakpoint-medium) {
            display: block;
        height: 60px;
    }
}
</style>